// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { LabelContents } from 'components/molecules/LabelContents';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { createAxios } from 'ts/createAxios';
import {
  getFormatCurrentDate,
  getFormatCurrentDateTime,
  getFormatDateWithCurrentTimezone,
} from 'ts/formatDate';
import { DATE_FORMAT } from 'util/ConstantValues';
import { CollectHistory, EdiStatus } from 'util/Types';
import styles from './HistoryCollected.module.scss';
import EdiStatusTag from 'components/atoms/EdiStatusTag';
import { OperatorType } from 'util/Enums';
import { normalizeNumber } from 'util/commons';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { getCollectHistoryCSVDataGroupedByTenant } from 'util/csvExportUtils';

export type Props = {
  id?: string;
};

export const HistoryCollected = () => {
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { t } = useTranslation();
  const [collectedData, setCollectedData] = useState<CollectHistory[]>([]);

  const callApi = async () => {
    const res = await commonsApi.get('/work-histories/collect/receives');
    setCollectedData(res.data);
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <div className={styles.historyCollected}>
      {collectedData &&
        collectedData.length > 0 &&
        collectedData.map((collect, index) => {
          return (
            <div className={styles.historyCollectedCard} key={index}>
              <div className={styles.historyCollectedContentCol}>
                <LabelContents
                  label={t('common_history_field.date_time')}
                  contents={getFormatDateWithCurrentTimezone(
                    collect.receivedDatetime,
                    DATE_FORMAT.slaYMDHm
                  )}
                />
                <LabelContents
                  label={t('common_history_field.responsible_person')}
                  contents={collect.userId + '(' + collect.userName + ')'}
                />
                <LabelContents
                  label={t('common_history_field.place')}
                  contents={collect.areaName}
                />
                <LabelContents
                  label={t('common_history_field.material_name')}
                  contents={collect.description}
                />
                <LabelContents
                  label={t('common_history_field.weight')}
                  contents={
                    normalizeNumber({
                      value: collect.weight,
                    }) + 'kg'
                  }
                />
                <LabelContents
                  label={t('common_history_field.registration_id')}
                  contents={collect.targetIds.join('\n')}
                />
                <LabelContents
                  label={t('common_history_field.label_manifest_number')}
                  contents={collect.ediNumber}
                  hideOnEmpty
                />
                <LabelContents
                  label={t('common_history_field.label_edi_id')}
                  hideOnEmpty
                  contents={
                    collect.receivedEdiStatus != null &&
                    collect.receivedEdiStatus !== EdiStatus.None && (
                      <EdiStatusTag
                        status={collect.receivedEdiStatus}
                        operatorType={OperatorType.Collects}
                      />
                    )
                  }
                />
              </div>
              <div className={styles.historyCollectedActionCol}>
                <CSVLink
                  filename={`${t(
                    'histories.csv_file_name_collect_by_waste_unit'
                  )}_${getFormatCurrentDate()}`}
                  data={getCollectHistoryCSVDataGroupedByTenant(
                    collect,
                    getFormatCurrentDateTime(),
                    t
                  )}
                >
                  <IconDownload />
                </CSVLink>
              </div>
            </div>
          );
        })}
    </div>
  );
};
