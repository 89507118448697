// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './Histories.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { HistoryEmissions } from 'components/organisms/HistoryEmissions';
import { HistoryCollects } from 'components/organisms/HistoryCollects';
import { HistoryCollected } from 'components/organisms/HistoryCollected';
import { HistoryCollectComplete } from 'components/organisms/HistoryCollectComplete';
import { HistoryCompressionReceives } from 'components/organisms/HistoryCompressionReceives';
import { HistoryCompressionCompletes } from 'components/organisms/HistoryCompressionCompletes';
import { HistoryRecycleReceives } from 'components/organisms/HistoryRecycleReceives';
import { HistoryRecycleCompletes } from 'components/organisms/HistoryRecycleCompletes';
import { DownloadModal } from 'components/organisms/DownloadModal';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import {
  CompressionCompleteData,
  CompressionReceiveData,
  EdiStatus,
  ItemType,
  RecycleReceiveData,
  Site,
} from 'util/Types';
import { OperatorType } from 'util/Enums';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { ApiInfo } from 'components/organisms/DownloadModal';
import { TabletTab } from 'components/molecules/TabletTab';
import { DATE_FORMAT } from 'util/ConstantValues';
import { useTranslation } from 'react-i18next';
import _snakeCase from 'lodash/snakeCase';
import { getEdiStatusLabel } from 'util/commons';

const Histories = () => {
  const { t } = useTranslation();
  const tabNames = {
    Emissions: [
      t('histories.tabs.emission_registration_or_collect_request'),
      t('histories.tabs.emission_collected'),
    ],
    Collects: [
      t('histories.tabs.collect_list'),
      t('histories.tabs.collect_delivery_completed_list'),
    ],
    Compressions: [
      t('histories.tabs.compression_receipt_list'),
      t('histories.tabs.compression_shipping_registration_list'),
    ],
    Recycle: [
      t('histories.tabs.recycle_receipt_list'),
      t('histories.tabs.recycle_product_registration'),
    ],
  };

  const apiInfo = {
    Emissions: [
      {
        fileName: t('histories.title') + '（' + tabNames.Emissions[0] + '）',
        url: '/work-histories/emission/requests?siteId=',
        header: [
          t('histories.label_csv_emitting_date'),
          t('histories.label_csv_emitting_time'),
          t('histories.label_csv_emissions_site_name'),
          t('histories.label_csv_group1'),
          t('histories.label_csv_group2'),
          t('histories.label_csv_tenant_code'),
          t('histories.label_csv_tenant'),
          t('histories.label_csv_brand_owner'),
          t('histories.label_csv_category'),
          t('histories.label_csv_item'),
          t('histories.label_csv_registration_id'),
          t('histories.label_csv_weight_or_quantity'),
          t('histories.label_csv_container'),
          t('histories.label_csv_quantity_in_case_of_unscalable'),
          t('histories.label_csv_weight_kg'),
          t('histories.label_csv_collection_request_date_time'),
          t('histories.label_csv_collection_request_by'),
          t('histories.label_csv_processing_method'),
          t('histories.label_csv_CO2_emission_factor'),
          t('histories.label_csv_collecting_company'),
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createCsv: (data: any[]) => {
          return data.map((emission) => [
            getFormatDateWithCurrentTimezone(
              emission.emission.datetime,
              DATE_FORMAT.full_date
            ),

            getFormatDateWithCurrentTimezone(
              emission.emission.datetime,
              DATE_FORMAT.hhss
            ),

            emission.emission.site,
            emission.emission.section,
            emission.emission.floor,
            emission.emission.tenantCode,
            emission.emission.tenant,
            emission.emission.brandOwner,
            emission.emission.itemCategory,
            emission.emission.item,
            emission.id,
            emission.emission.itemType === ItemType.FIX
              ? t('common.fixed')
              : t('common.optional'),
            emission.emission.tareInformation
              ? emission.emission.tareInformation
              : '',
            emission.emission.itemType === ItemType.FIX &&
            emission.emission.quantity !== ''
              ? emission.emission.quantity
              : '',
            emission.emission.weight && emission.emission.weight !== ''
              ? emission.emission.weight
              : '',
            emission.request.datetime
              ? getFormatDateWithCurrentTimezone(
                  emission.request.datetime,
                  DATE_FORMAT.slaYMDHms
                )
              : '',
            emission.request.userName,
            emission.emission.recycleMethod,
            emission.emission.emissionFactorValue,
            emission.collect.operatorName,
          ]);
        },
      },
      {
        fileName: t('histories.title') + '（' + tabNames.Emissions[1] + '）',
        url: '/work-histories/emission/collected?siteId=',
        header: [
          t('histories.label_csv_emitting_date'),
          t('histories.label_csv_emitting_time'),
          t('histories.label_csv_emissions_site_name'),
          t('histories.label_csv_group1'),
          t('histories.label_csv_group2'),
          t('histories.label_csv_tenant'),
          t('histories.label_csv_brand_owner'),
          t('histories.label_csv_category'),
          t('histories.label_csv_item'),
          t('histories.label_csv_registration_id'),
          t('histories.label_csv_weight_or_quantity'),
          t('histories.label_csv_container'),
          t('histories.label_csv_quantity_in_case_of_unscalable'),
          t('histories.label_csv_weight_kg'),
          t('histories.label_csv_collection_request_date_time'),
          t('histories.label_csv_collection_request_by'),
          t('histories.label_csv_processing_method'),
          t('histories.label_csv_CO2_emission_factor'),
          t('histories.label_csv_collecting_company'),
          t('histories.label_csv_collecting_date_time'),
          t('histories.label_edi_id'),
          t('histories.label_manifest_number'),
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createCsv: (data: any[]) => {
          return data.map((emission) => [
            getFormatDateWithCurrentTimezone(
              emission.emission.datetime,
              DATE_FORMAT.full_date
            ),

            getFormatDateWithCurrentTimezone(
              emission.emission.datetime,
              DATE_FORMAT.hhss
            ),

            emission.emission.site,
            emission.emission.section,
            emission.emission.floor,
            emission.emission.tenant,
            emission.emission.brandOwner,
            emission.emission.itemCategory,
            emission.emission.item,
            emission.id,
            emission.emission.itemType === ItemType.FIX
              ? t('common.fixed')
              : t('common.optional'),
            emission.emission.tareInformation,
            emission.emission.itemType === ItemType.FIX &&
            emission.emission.quantity !== ''
              ? emission.emission.quantity
              : '',
            emission.emission.weight !== '' ? emission.emission.weight : '',
            getFormatDateWithCurrentTimezone(
              emission.request.datetime,
              DATE_FORMAT.slaYMDHms
            ),
            emission.request.userName,
            emission.emission.recycleMethod,
            emission.emission.emissionFactor.emissionFactorValue,
            emission.collect.operatorName,
            getFormatDateWithCurrentTimezone(
              emission.collect.datetime,
              DATE_FORMAT.slaYMDHms
            ),
            getEdiStatusLabel({
              t,
              status: emission.collect.ediStatus,
              operatorType: OperatorType.Emissions,
            }),
            emission.collect.ediStatus != null &&
            ![EdiStatus.None, EdiStatus.Failed, EdiStatus.Processing].includes(
              emission.collect.ediStatus
            )
              ? emission.collect.ediNumber
              : null,
          ]);
        },
      },
    ],
    Collects: [
      {
        fileName: t('histories.title') + '（' + tabNames.Collects[0] + '）',
        url: '/work-histories/collect/receives?',
        header: [
          t('histories.label_csv_collecting_date_time'),
          t('histories.label_csv_responsible_person'),
          t('histories.label_csv_place'),
          t('histories.label_csv_material_name'),
          t('histories.label_csv_weight_kg'),
          t('histories.label_csv_emission_id'),
          t('histories.label_edi_id'),
          t('histories.label_manifest_number'),
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createCsv: (data: any[]) => {
          return data.map((collect) => [
            getFormatDateWithCurrentTimezone(
              collect.receivedDatetime,
              DATE_FORMAT.slaYMDHm
            ),
            collect.userId + '(' + collect.userName + ')',
            collect.areaName,
            collect.description,
            collect.weight,
            collect.targetIds.join('\n'),
            getEdiStatusLabel({
              status: collect.receivedEdiStatus,
              t,
              operatorType: OperatorType.Collects,
            }),
            collect.receivedEdiStatus != null &&
            collect.receivedEdiStatus !== EdiStatus.None
              ? collect.ediNumber
              : null,
          ]);
        },
      },
      {
        fileName: t('histories.title') + '（' + tabNames.Collects[1] + '）',
        url: '/work-histories/collect/delivered?',
        header: [
          t('histories.label_csv_collecting_date_time'),
          t('histories.label_csv_delivery_date_time'),
          t('histories.label_csv_responsible_person'),
          t('histories.label_csv_place'),
          t('histories.label_csv_material_name'),
          t('histories.label_csv_weight_kg'),
          t('histories.label_csv_emission_id'),
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createCsv: (data: any[]) => {
          return data.map((collect) => [
            getFormatDateWithCurrentTimezone(
              collect.receivedDatetime,
              DATE_FORMAT.slaYMDHm
            ),
            getFormatDateWithCurrentTimezone(
              collect.deliveredDatetime,
              DATE_FORMAT.slaYMDHm
            ),
            collect.userId + '(' + collect.userName + ')',
            collect.areaName,
            collect.description,
            collect.weight,
            collect.targetIds.join('\n'),
          ]);
        },
      },
    ],
    Compressions: [
      {
        fileName: t('histories.title') + '（' + tabNames.Compressions[0] + '）',
        url: '/work-histories/compression/receives?',
        header: [
          t('histories.label_csv_received_date_time'),
          t('histories.label_csv_responsible_person'),
          t('histories.label_csv_place'),
          t('histories.label_csv_material_name'),
          t('histories.label_csv_weight_kg'),
          t('histories.label_csv_reception_method'),
          t('histories.label_csv_volume_reduction_id'),
          t('histories.label_edi_id'),
          t('histories.label_manifest_number'),
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createCsv: (data: CompressionReceiveData[]) => {
          return data.map((item) => [
            getFormatDateWithCurrentTimezone(
              item.receivedDatetime,
              DATE_FORMAT.slaYMDHm
            ),
            item.userId + '(' + item.userName + ')',
            item.deliveryFromSiteName,
            item.description,
            item.weight,
            t(`histories.${_snakeCase(item.receptionMethod)}`),
            item.targetIds.map(({ id }) => id).join('\n'),
            item.targetIds[0].ediStatus != null &&
            item.targetIds[0].ediStatus !== EdiStatus.None
              ? getEdiStatusLabel({
                  status: item.targetIds[0].ediStatus,
                  t,
                })
              : '',
            item.targetIds[0].ediStatus != null &&
            item.targetIds[0].ediStatus !== EdiStatus.None &&
            item.targetIds[0].ediNumber != null
              ? item.targetIds[0].ediNumber
              : '',
          ]);
        },
      },
      {
        fileName: t('histories.title') + '（' + tabNames.Compressions[1] + '）',
        url: '/work-histories/compression/delivered?',
        header: [
          t('histories.label_csv_requested_date_time'),
          t('histories.label_csv_processed_date_time'),
          t('histories.label_csv_responsible_person'),
          t('histories.label_csv_shipping_address'),
          t('histories.label_csv_material_name'),
          t('histories.label_csv_shipping_quality'),
          t('histories.label_csv_weight_kg'),
          t('histories.label_csv_volume_reduction_id'),
        ],
        createCsv: (data: CompressionCompleteData[]) => {
          return data.reduce((acc: string[][], cur) => {
            for (const key in cur.byCompression) {
              acc.push([
                getFormatDateWithCurrentTimezone(
                  cur.requestedDatetime,
                  DATE_FORMAT.slaYMDHm
                ),
                getFormatDateWithCurrentTimezone(
                  cur.byCompression[key].registeredDatetime,
                  DATE_FORMAT.slaYMDHm
                ),
                cur.userId + '(' + cur.userName + ')',
                cur.deliveryToOperatorName,
                cur.description,
                cur.byCompression[key].grade,
                cur.byCompression[key].weight,
                key,
              ]);
            }
            return acc;
          }, []);
        },
      },
    ],
    Recycle: [
      {
        fileName: t('histories.title') + '（' + tabNames.Recycle[0] + '）',
        url: '/work-histories/recycle/receives?',
        header: [
          t('histories.label_csv_date_time'),
          t('histories.label_csv_responsible_person'),
          t('histories.label_csv_shipping_origin'),
          t('histories.label_csv_material_name'),
          t('histories.label_csv_weight_kg'),
          t('histories.label_csv_reception_method'),
          t('histories.label_csv_volume_reduction_id'),
          t('histories.label_edi_id'),
          t('histories.label_manifest_number'),
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createCsv: (data: RecycleReceiveData[]) => {
          return data.map((item) => [
            getFormatDateWithCurrentTimezone(
              item.datetime as string,
              DATE_FORMAT.slaYMDHm
            ),
            item.userId + '(' + item.userName + ')',
            item.deliveryFromOperatorName,
            item.description,
            item.weight,
            t(`histories.${_snakeCase(item.receptionMethod)}`),
            item.targetIds.map(({ id }) => id).join('\n'),
            item.targetIds[0].ediStatus != null &&
            item.targetIds[0].ediStatus !== EdiStatus.None
              ? getEdiStatusLabel({
                  status: item.targetIds[0].ediStatus,
                  t,
                })
              : '',
            item.targetIds[0].ediStatus != null &&
            item.targetIds[0].ediStatus !== EdiStatus.None &&
            item.targetIds[0].ediNumber != null
              ? item.targetIds[0].ediNumber
              : '',
          ]);
        },
      },
      {
        fileName: t('histories.title') + '（' + tabNames.Recycle[1] + '）',
        url: '/work-histories/recycle/complete?',
        header: [
          t('histories.label_csv_date_time'),
          t('histories.label_csv_responsible_person'),
          t('histories.label_csv_material_name'),
          t('histories.label_csv_kind'),
          t('histories.label_csv_shipping_quality'),
          t('histories.label_csv_weight_kg'),
          t('histories.label_csv_product_id'),
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createCsv: (data: any[]) => {
          return data.map((item) => [
            getFormatDateWithCurrentTimezone(
              item.datetime as string,
              DATE_FORMAT.slaYMDHm
            ),
            item.userId + '(' + item.userName + ')',
            item.description,
            item.materialType.name,
            item.grade.name,
            item.weight,
            item.targetIds.join('\n'),
          ]);
        },
      },
    ],
  };
  const api = createAxios();
  const user = useSelector(selectUser);
  const [sites, setSites] = useState<Site[]>([]);
  const [historyEmissionsSiteId, setHistoryEmissionsSiteId] =
    useState<string>();
  const [collectedEmissionsSiteId, setCollectedEmissionsSiteId] =
    useState<string>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useState<boolean>(false);

  function getApiInfo(idx): ApiInfo {
    if (user.operatorType == OperatorType.Emissions) {
      let result: ApiInfo;
      if (idx == 0) {
        result = {
          fileName: apiInfo[user.operatorType][idx]['fileName'],
          url:
            apiInfo[user.operatorType][idx]['url'] +
            historyEmissionsSiteId +
            '&',
          header: apiInfo[user.operatorType][idx]['header'],
          createCsv: apiInfo[user.operatorType][idx]['createCsv'],
        };
      } else {
        result = {
          fileName: apiInfo[user.operatorType][idx]['fileName'],
          url:
            apiInfo[user.operatorType][idx]['url'] +
            collectedEmissionsSiteId +
            '&',
          header: apiInfo[user.operatorType][idx]['header'],
          createCsv: apiInfo[user.operatorType][idx]['createCsv'],
        };
      }
      return result;
    }

    return apiInfo[user.operatorType][tabIndex];
  }

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
  };
  const openDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };

  const callSiteApi = async () => {
    const res = await api.get('/emissions/sites');
    setSites(res.data);
  };
  useEffect(() => {
    if (user.operatorType == OperatorType.Emissions) {
      callSiteApi();
    }
  }, []);

  return (
    <div>
      <PrimaryTemplate
        h1={t('histories.title')}
        download={openDownloadModal}
        className="history"
      >
        <TabletTab
          tabItems={[
            tabNames[user.operatorType][0],
            tabNames[user.operatorType][1],
          ]}
          setTabIndex={setTabIndex}
          defaultTabIndex={tabIndex}
          renderContent={({ index }) => {
            {
              if (user.operatorType == OperatorType.Emissions) {
                return (
                  <div className="scroll-contents">
                    {index === 0 && (
                      <HistoryEmissions
                        key={`HistoryEmissions-${tabIndex}`}
                        sites={sites}
                        setSiteId={setHistoryEmissionsSiteId}
                        isActive={tabIndex === 0}
                      />
                    )}
                    {index === 1 && (
                      <HistoryCollects
                        key={`HistoryCollects-${tabIndex}`}
                        sites={sites}
                        setSiteId={setCollectedEmissionsSiteId}
                        isActive={tabIndex === 1}
                      />
                    )}
                  </div>
                );
              } else if (user.operatorType == OperatorType.Collects) {
                return (
                  <div className="scroll-contents">
                    {index === 0 && <HistoryCollected />}
                    {index === 1 && <HistoryCollectComplete />}
                  </div>
                );
              } else if (user.operatorType == OperatorType.Compressions) {
                return (
                  <div className="scroll-contents">
                    {index === 0 && <HistoryCompressionReceives />}
                    {index === 1 && <HistoryCompressionCompletes />}
                  </div>
                );
              } else if (user.operatorType == OperatorType.Recycle) {
                return (
                  <div className="scroll-contents">
                    {index === 0 && <HistoryRecycleReceives />}
                    {index === 1 && <HistoryRecycleCompletes />}
                  </div>
                );
              }
            }
          }}
        />
      </PrimaryTemplate>
      <DownloadModal
        isOpen={isDownloadModalOpen}
        onCancelClick={closeDownloadModal}
        apiInfo={getApiInfo(tabIndex)}
      />
    </div>
  );
};

export default Histories;
