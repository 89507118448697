// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './SubstituteRegist.module.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { Form } from 'react-final-form';
import { ConfirmModal } from 'components/organisms/ConfirmModal';
import { validateInputEmissionNumber } from 'util/validator';
import {
  ImagePath,
  QUANTITY_INPUT_MAX_LENGTH,
  REGEX_OBJECT,
} from 'util/ConstantValues';
import { InputDataListField } from 'components/molecules/InputDataListField';
import { CategoryItem, ItemType, TareData, TareItem } from 'util/Types';
import { useTranslation } from 'react-i18next';
import { handleError } from 'util/errorHandler';
import { IconFixedItem } from 'components/atoms/icons/IconFixedItem';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { Path } from 'routes';
import { getUnitLabel, mergeClasses, normalizeNumber } from 'util/commons';
import { useWeightInput } from 'util/hooks/useScaleWeightInput';

type Props = {
  emissionType: CategoryItem;
  // 代理登録を行う対象となる排出拠点ID
  substituteRegistFromSiteId: string;
  afterSubmit: () => void;
};

type Input = {
  quantity?: string;
};

export function SubstituteRegistInput({
  emissionType,
  afterSubmit,
  substituteRegistFromSiteId,
}: Props) {
  const api = createAxios();
  const history = useHistory();
  const [tare, setTares] = useState<TareData>();
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<TareItem>();
  const { t } = useTranslation();
  const initInputData: Input = {
    quantity: '',
  };

  const { weightInputRef } = useWeightInput({
    receiveValueFromScaleCondition: !isConfirm,
    itemType: emissionType.type,
  });

  const fetchTares = () => {
    api
      .get<TareData>(`/tares?siteId=${substituteRegistFromSiteId}`)
      .then((res) => {
        setTares(res.data);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleSelected = (tareItem: TareItem) => {
    setSelectedItem((prev) => {
      if (prev?.id === tareItem.id) {
        return undefined;
      }

      return tareItem;
    });
  };

  useEffect(() => {
    fetchTares();
  }, []);

  const getConfirmQuantity = (quantity?: string) => {
    if (emissionType.type === ItemType.OPTIONAL && selectedItem) {
      const subQuantity = Number(quantity) - Number(selectedItem.weight);

      return subQuantity < 0
        ? 0
        : normalizeNumber({
            value: subQuantity,
            commaSplit: false,
          });
    }

    return quantity;
  };

  const submitSubstituteRegist = (
    values: Input,
    isSubmitAndFinish?: boolean
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const waste = [] as any[];
    if (!isConfirm) return;
    if (values.quantity && values.quantity != '') {
      const quantity = getConfirmQuantity(values.quantity);
      if (isNaN(Number(quantity))) {
        alert(t('emission_input.input_number'));
      }
      waste.push({
        type: emissionType.type,
        wasteUnitId: Number(emissionType.id),
        quantity: Number(quantity),
        // tenantId は POST しない
        // ...(tenantId ? { tenantId: Number(tenantId) } : {}),
        ...(emissionType.type === ItemType.OPTIONAL
          ? {
              tareName: selectedItem?.name,
              tareWeight: selectedItem?.weight,
            }
          : {}),
      });
    }
    if (waste.length === 0) {
      return;
    }
    api
      .post('/collects/substitute-regists', {
        siteId: substituteRegistFromSiteId,
        waste: waste,
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((res) => {
        setIsConfirm(false);
        if (!isSubmitAndFinish) {
          afterSubmit();
        } else {
          history.push(Path.collects);
        }
        toast.success(t('messages.M_109'));
        // FIXME: Android 向け機能は一旦コメントアウト
        // if (window && window.postEmissions) {
        //   window.postEmissions(
        //     JSON.stringify([
        //       {
        //         ...res.data[0],
        //         emissionDatetime: getFormatDateWithCurrentTimezone(
        //           res.data[0].emissionDatetime,
        //           DATE_FORMAT.slaYMDHms
        //         ),
        //       },
        //     ]),
        //     i18n.language
        //   );
        // }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  return (
    <div className={styles.emissionInput}>
      <Form<Input>
        initialValue={initInputData}
        validate={(values) => {
          return {
            quantity: validateInputEmissionNumber(
              values.quantity || '',
              emissionType.type
            ),
          };
        }}
        onSubmit={() => {}}
      >
        {({ handleSubmit, valid, values }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.formInput}>
              <div>
                <div className={styles.inputType}>
                  <div className={styles.emissionBox}>
                    <img
                      className={styles.emissionImage}
                      src={
                        emissionType.imageUrl
                          ? `${process.env.REACT_APP_CLOUDFRONT}${emissionType.imageUrl}`
                          : ImagePath.defaultImage
                      }
                    />
                  </div>

                  <div className={styles.commonInfo}>
                    <div className={styles.info}>
                      <div className={styles.labelBox}>
                        <div className={styles.label}>{emissionType.name}</div>
                        {emissionType.type === ItemType.FIX && (
                          <div className={styles.type}>
                            <div className={`${styles.itemType}`}>
                              <IconFixedItem />
                            </div>
                          </div>
                        )}
                      </div>

                      {emissionType.type === ItemType.FIX && (
                        <div className={styles.weightFix}>{`${
                          emissionType.convertKg
                        }kg/${emissionType.quantity}${getUnitLabel(
                          emissionType.unit
                        )}`}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.weight}>
                  <div className={styles.weightField}>
                    <InputDataListField
                      placeholder="0"
                      inputRef={weightInputRef}
                      name="quantity"
                      required
                      type="text"
                      maxlength={QUANTITY_INPUT_MAX_LENGTH}
                      validRegex={
                        emissionType.type === ItemType.FIX
                          ? REGEX_OBJECT.number
                          : REGEX_OBJECT.fiveDigitsWith2Decimal
                      }
                    />
                    {emissionType.type !== ItemType.FIX && (
                      <span className={styles.weightUnit}>kg</span>
                    )}
                  </div>
                </div>

                {emissionType.type === ItemType.OPTIONAL && (
                  <div className={styles.emissionTare}>
                    {tare &&
                      tare.tares.map((item) => {
                        return (
                          <span
                            key={item.id}
                            className={mergeClasses({
                              [styles.emissionSelected]:
                                selectedItem?.id === item.id,
                            })}
                            onClick={() => {
                              handleSelected(item);
                            }}
                          >
                            {`${
                              item.name.length > 20
                                ? `${item.name.slice(
                                    0,
                                    18 - item.weight.length
                                  )}...`
                                : item.name
                            } (${normalizeNumber({
                              value: item.weight,
                            })}kg)`}
                          </span>
                        );
                      })}
                  </div>
                )}
              </div>
              <button
                className={`${styles.submitButton} ${
                  !valid ? styles.disableButton : ''
                }`}
                disabled={!valid}
                type="button"
                onClick={() => {
                  setIsConfirm(true);
                  return false;
                }}
              >
                {t('emission_input.button_submit')}
              </button>
              <ConfirmModal
                fullBottomWidth
                title={t('emission_input.confirm_title')}
                submitLabel={
                  t('substitute_regist_new.collect_and_finish') ||
                  '代理登録して回収する'
                }
                isOpen={isConfirm}
                emissionType={emissionType}
                closeEditModal={() => {
                  setIsConfirm(false);
                }}
                onClick={() => {
                  submitSubstituteRegist(values, true);
                }}
                confirmContents={[
                  ...(selectedItem
                    ? [
                        {
                          label: `${selectedItem.name}:`,
                          contents: `${normalizeNumber({
                            value: selectedItem.weight,
                          })}kg`,
                        },
                      ]
                    : []),
                  emissionType.type === ItemType.FIX
                    ? {
                        label: t('emission_input.label_item_name'),
                        contents: `${emissionType.name} ${
                          emissionType.convertKg
                        }kg/${emissionType.quantity}${getUnitLabel(
                          emissionType.unit
                        )}`,
                      }
                    : {
                        label: t('emission_input.label_item_name'),
                        contents: emissionType.name,
                      },
                  {
                    label:
                      emissionType.type == ItemType.OPTIONAL
                        ? t('emission_input.label_weight')
                        : t('emission_input.label_content'),
                    contents: `${getConfirmQuantity(values.quantity)} ${
                      emissionType.type == ItemType.FIX &&
                      emissionType.inputUnit
                        ? emissionType.inputUnit
                        : emissionType.type == ItemType.OPTIONAL
                        ? 'kg'
                        : ''
                    }`,
                  },
                  emissionType.type === ItemType.FIX
                    ? {
                        label: t('emission_input.label_weight'),
                        contents: `${
                          Number(emissionType.convertKg) *
                          Number(values.quantity)
                        } kg`,
                      }
                    : { label: '', contents: '' },
                ]}
              />
            </form>
          );
        }}
      </Form>
    </div>
  );
}
