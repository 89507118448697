// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './index.module.scss';
import { Modal } from 'components/atoms/Modal';
import { useTranslation } from 'react-i18next';
import { IconClose } from 'components/atoms/icons/IconClose';
import { CategoryItem, ItemType, TareItem } from 'util/Types';
import { getUnitLabel, mergeClasses, normalizeNumber } from 'util/commons';
import BigNumber from 'bignumber.js';

export type EmissionConfirmModalProps = {
  isOpen: boolean;
  emissionType: CategoryItem;
  quantity: string;
  closeEditModal: () => void;
  onClick: () => void;
  onClickSubmitAndFinish?: () => void;
  tare?: TareItem;
};

export const EmissionConfirmModal = (props: EmissionConfirmModalProps) => {
  const { t } = useTranslation();
  const {
    isOpen,
    emissionType,
    quantity,
    closeEditModal,
    onClick,
    onClickSubmitAndFinish,
    tare,
  } = props;

  const getWeight = (convertKg: string | number | null, quantity: string) => {
    if (!convertKg) return 0;
    const kg = new BigNumber(convertKg);
    return kg.multipliedBy(quantity);
  };

  const confirmContents =
    emissionType.type === ItemType.FIX
      ? [
          {
            label: t('emission_input.label_item_name'),
            contents: `${emissionType.name} ${emissionType.convertKg}kg/${
              emissionType.quantity
            }${getUnitLabel(emissionType.unit)}`,
          },
          {
            label: t('emission_input.label_weight'),
            contents: `${normalizeNumber({
              value: getWeight(emissionType.convertKg, quantity),
              toFixed: false,
            })} kg`,
          },
        ]
      : [
          {
            label: t('emission_input.label_item_name'),
            contents: emissionType.name,
          },
          { label: '', contents: '' },
        ];

  const renderUnitInput = () => {
    if (emissionType.type == ItemType.FIX && emissionType.inputUnit) {
      return emissionType.inputUnit;
    }

    if (emissionType.type == ItemType.OPTIONAL) {
      return 'kg';
    }

    return '';
  };

  return (
    <section
      className={mergeClasses(
        styles.emissionConfirmModal,
        isOpen ? styles.open : styles.closed
      )}
    >
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.top}>
            <div className={styles.title}>
              {t('emission_input.confirm_title')}
            </div>
            <div className={styles.closeIcon} onClick={closeEditModal}>
              <IconClose />
            </div>
          </div>

          <div className={styles.body}>
            {tare && (
              <div className={styles.tare}>
                <div className={styles.tareName}>{tare.name}:</div>
                <div>
                  {normalizeNumber({
                    value: tare.weight,
                  })}
                  kg
                </div>
              </div>
            )}

            <div className={styles.confirmInfo}>
              {confirmContents.map((item, i) => {
                return (
                  item.label &&
                  item.contents && (
                    <div className={styles.confirmInfoRow} key={i}>
                      <div className={styles.confirmInfoLabel}>
                        {item.label}
                      </div>
                      <div className={styles.confirmInfoContents}>
                        {item.contents}
                      </div>
                    </div>
                  )
                );
              })}
            </div>

            <div className={styles.contentUnit}>
              <div className={styles.quantity}>
                <span>{`${normalizeNumber({
                  value: quantity,
                })}`}</span>
              </div>
              <div className={styles.weightUnit}>
                <span>{renderUnitInput()}</span>
              </div>
            </div>
          </div>
          <div className={styles.confirmBtn}>
            <button
              className={styles.submitButton}
              type="button"
              onClick={onClick}
            >
              {t('common.button.register_continue')}
            </button>
            <button
              className={styles.finishButton}
              type="button"
              onClick={onClickSubmitAndFinish}
            >
              {t('common.button.register_and_finish')}
            </button>
          </div>
        </div>
      </Modal>
    </section>
  );
};
