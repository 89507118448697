// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './ExpandableList.module.scss';
import { useLayoutEffect, useRef, useState } from 'react';
import _debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { IconSeeMore } from 'components/atoms/icons/IconSeeMore';
import { SeeMoreModal } from 'components/molecules/SeeMoreModal';

type Props = {
  data: string[];
  emptyRender?: React.ReactNode;
};

export function ExpandableList({ data, emptyRender }: Props) {
  const { t } = useTranslation();
  const [openSeeMore, setOpenSeeMore] = useState<boolean>(false);
  const [showMoreDisplayed, setShowMoreDisplayed] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const detectShowMoreDisplayed = () => {
      if (textRef.current) {
        setShowMoreDisplayed(
          textRef.current.scrollHeight > textRef.current.clientHeight
        );
      }
    };

    detectShowMoreDisplayed();

    const handleDetectShowMoreDisplayed = _debounce(
      detectShowMoreDisplayed,
      200
    );

    window.addEventListener('resize', handleDetectShowMoreDisplayed);

    return () => {
      window.removeEventListener('resize', handleDetectShowMoreDisplayed);
    };
  }, [data]);

  if (!data.length) {
    return <>{emptyRender}</>;
  }

  return (
    <div>
      <p>
        <span ref={textRef} className={styles.text}>
          {data.join(', ')}
        </span>
        {showMoreDisplayed && (
          <span>
            <IconSeeMore onClick={() => setOpenSeeMore(true)} />
          </span>
        )}
      </p>

      {showMoreDisplayed && openSeeMore && (
        <SeeMoreModal
          title={t('tare_management.label_tenant')}
          data={data}
          onClose={() => {
            setOpenSeeMore(false);
          }}
        />
      )}
    </div>
  );
}
