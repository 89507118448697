// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect, useMemo, useRef, useState } from 'react';
import { Label } from 'components/atoms/Label';
import { ErrorText } from 'components/atoms/ErrorText';
import useOutsideClick from 'util/hooks/useClickOutside';
import styles from './SelectFieldMultipleChoise.module.scss';
import { IconDropDown } from 'components/atoms/icons/IconDropDown';
import Tag from 'components/atoms/Tag';
import { CheckBoxCardV2 } from '../CheckBoxCardV2';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'util/commons';
import _uniqBy from 'lodash/uniqBy';
import { IconClose } from 'components/atoms/icons/IconClose';

export type OptionItem = {
  id: string | number;
  name: string;
  [key: string]: string | number;
};

interface Props {
  name: string;
  selectedOptions: OptionItem[] | (string | number)[];
  options: OptionItem[];
  searchable?: boolean;
  onChange: (tags: OptionItem[]) => void;
  className?: string;
  label?: string;
  required?: boolean;
  placeholder?: string | null;
  disabled?: boolean;
  maxTagError?: string;
  checkAllWithCheckBox?: boolean;
  darkMode?: boolean;
  allowClear?: boolean;
}

export function SelectFieldMultipleChoise({
  label,
  selectedOptions: selectedOptionProps,
  placeholder,
  options,
  searchable,
  disabled,
  className,
  onChange,
  required,
  maxTagError,
  checkAllWithCheckBox,
  darkMode,
  allowClear,
}: Props) {
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const onClickOutside = () => {
    setIsOpenOptions(false);
  };
  const { ref } = useOutsideClick({ onClickOutside });
  const [checkError, setCheckError] = useState(false);
  const errorRef = useRef(false);
  const [checkAll, setCheckAll] = useState(false);
  const divRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useState<OptionItem[]>([]);

  const optionMapper = filterOptions.reduce(
    (rs: Record<string, string>, { id, name }) => {
      rs[id] = name;

      return rs;
    },
    {}
  );

  const selectedOptions: OptionItem[] =
    typeof selectedOptionProps[0] !== 'object'
      ? (selectedOptionProps as (string | number)[]).reduce(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (rs: OptionItem[], val: any) => {
            const label = optionMapper[val];

            if (label) {
              rs.push({
                id: val,
                name: label,
              });
            }

            return rs;
          },
          []
        )
      : (selectedOptionProps as OptionItem[]);

  useEffect(() => {
    if (!errorRef.current && isOpenOptions) {
      errorRef.current = true;
    }
    if (
      errorRef.current &&
      !isOpenOptions &&
      required &&
      !disabled &&
      !selectedOptions.length
    ) {
      setCheckError(true);
    } else setCheckError(false);
  }, [isOpenOptions, selectedOptions]);

  useEffect(() => {
    setCheckAll(
      selectedOptions.length > 0 &&
        filterOptions.every((option) =>
          selectedOptions.find(
            (item) => option.id.toString() === item.id.toString()
          )
        )
    );
  }, [selectedOptions.length, filterOptions.length]);

  useEffect(() => {
    setFilterOptions(options);
  }, [isOpenOptions]);

  const handleSelectOption = (option: OptionItem) => {
    const newSelectedOptions = selectedOptions.find(
      (item) => item.id === option.id
    )
      ? selectedOptions.filter((item) => item.id !== option.id)
      : selectedOptions.concat(option);

    setTimeout(() => {
      const inp = ref.current?.getBoundingClientRect();
      divRef.current?.scrollTo({
        left: inp?.right,
        behavior: 'smooth',
      });
    }, 100);
    onChange && onChange(newSelectedOptions);
  };

  const handleOnSearch = (keyword: string) => {
    if (keyword === '') {
      setFilterOptions(options);
      return;
    }

    const searchOptions = options.filter((item) => {
      if (item.name.toLowerCase().includes(keyword.toLowerCase())) {
        return item;
      }
    });

    setFilterOptions(searchOptions);
  };

  const removeFilterOptions = useMemo(() => {
    return selectedOptions.filter((item) => !filterOptions.includes(item));
  }, [selectedOptions, filterOptions]);

  return (
    <div
      ref={ref}
      className={className ?? styles.selectMultipleField}
      onClick={() => {
        if (!disabled) {
          options.length > 0 && setIsOpenOptions(!isOpenOptions);
        }
      }}
    >
      {label && <Label text={label} tag={'div'} required={required} />}

      <div className={styles.select}>
        <div
          className={mergeClasses(styles.input, {
            [styles.inputDisable]: !!disabled,
            [styles.inputError]:
              !!(required && !selectedOptions.length && checkError) ||
              !!maxTagError,
            [styles.placeHolder]: !selectedOptions.length,
            [styles.darkModeStyle]: !!darkMode,
          })}
        >
          <div ref={divRef} className={styles.wrapSelected}>
            {selectedOptions.length > 0
              ? selectedOptions.map((option) => (
                  <Tag
                    key={option.id}
                    label={option.name}
                    onRemove={(e) => {
                      e.stopPropagation();
                      onChange(
                        selectedOptions.filter((t) => option.id !== t.id)
                      );
                    }}
                  />
                ))
              : placeholder}
          </div>
        </div>

        {isOpenOptions && (
          <ul className={darkMode ? styles.darkModeList : ''}>
            {searchable && (
              <li className={darkMode ? styles.darkModeItem : ''}>
                <div className={styles.searchInput}>
                  <input
                    placeholder={t('common.placeholder.search') || ''}
                    onChange={(e) => handleOnSearch(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </li>
            )}
            {filterOptions.length > 0 &&
              (checkAllWithCheckBox ? (
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    setCheckAll(!checkAll);

                    if (checkAll) {
                      onChange(searchable ? removeFilterOptions : []);
                    } else {
                      onChange(
                        _uniqBy([...selectedOptions, ...filterOptions], 'id')
                      );
                      setIsOpenOptions(false);
                    }
                  }}
                  className={darkMode ? styles.darkModeItem : ''}
                >
                  <CheckBoxCardV2 checked={checkAll} />
                  <span>{t('common.select_all')}</span>
                </li>
              ) : (
                <li
                  className={mergeClasses(styles.all, {
                    [styles.darkModeItem]: !!darkMode,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCheckAll(!checkAll);

                    if (checkAll) {
                      onChange(searchable ? removeFilterOptions : []);
                    } else {
                      onChange(
                        _uniqBy([...selectedOptions, ...filterOptions], 'id')
                      );
                      setIsOpenOptions(false);
                    }
                  }}
                >
                  {checkAll ? t('common.deselect_all') : t('common.select_all')}
                </li>
              ))}

            {filterOptions.map((option, i) => {
              return (
                <li
                  key={i}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectOption(option);
                  }}
                  className={darkMode ? styles.darkModeItem : ''}
                >
                  <CheckBoxCardV2
                    checked={
                      !!selectedOptions.find((item) => item.id === option.id)
                    }
                  />
                  <span className={darkMode ? styles.darkModeLabel : ''}>
                    {option.name}
                  </span>
                </li>
              );
            })}
          </ul>
        )}

        {allowClear && selectedOptions.length > 0 ? (
          <IconClose
            style={{
              position: 'absolute',
              top: '50%',
              right: 8,
              transform: 'translateY(-50%)',
            }}
            width={10}
            height={10}
            strokeColor="#9EA0A2"
            onClick={(e) => {
              e.stopPropagation();
              onChange?.([]);
            }}
          />
        ) : (
          <IconDropDown />
        )}
      </div>
      {((required && !disabled && checkError) || maxTagError) && (
        <ErrorText
          text={
            maxTagError ||
            t('messages.M_select_required', { field: label }) ||
            ''
          }
          className={styles.error}
        />
      )}
    </div>
  );
}
