// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect, useMemo, useState } from 'react';
import { getSlaFormatYearMonthDate, getUtcFormatDateTime } from 'ts/formatDate';
import {
  EmissionStatus,
  GetEmissionParams,
  UncollectEmission,
} from 'util/Types';
import { CustomCheckBox } from 'components/atoms/CustomCheckbox';
import styles from './UnCollectEmissions.module.scss';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, DEFAULT_PAGE } from 'util/ConstantValues';
import UnCollectChainTag from 'components/atoms/UnCollectChainTag';
import { normalizeNumber } from 'util/commons';
import {
  OptionItem,
  SelectFieldMultipleChoise,
} from 'components/molecules/SelectFieldMultipleChoise';
import TableFilterCalendar from 'components/molecules/TableFilterCalendar';
import IconCalendarTablet from 'components/atoms/icons/IconCalendarTablet';

interface Props {
  emissions: UncollectEmission[];
  itemOptions: OptionItem[];
  tenantOptions: OptionItem[];
  filter?: GetEmissionParams;
  onChange?: (checkedItems: string[]) => void;
  callApi: (params?: {
    page?: number;
    filterValue?: GetEmissionParams;
  }) => void;
}

export function UnCollectEmissions({
  emissions,
  itemOptions,
  tenantOptions,
  filter,
  onChange,
  callApi,
}: Props) {
  const [checkedItems, setCheckedItems] = useState({});
  const [allCheckedFlg, setAllCheckedFlg] = useState(false);
  const [tmpFilter, setTmpFilter] = useState<GetEmissionParams>(filter || {});

  const { t } = useTranslation();

  const checkableEmissions = useMemo(
    () => emissions.filter(({ nextStage }) => nextStage),
    [emissions]
  );

  useEffect(() => {
    const checkedList = Object.keys(checkedItems).filter(
      (key) => checkedItems[key] == true
    );

    if (onChange) {
      onChange(checkedList);
    }

    setAllCheckedFlg(
      checkedList.length > 0 && checkedList.length >= checkableEmissions.length
    );
  }, [checkedItems, setAllCheckedFlg]);

  useEffect(() => {
    setCheckedItems({});
    setAllCheckedFlg(!allCheckedFlg);
  }, [emissions]);

  const handleChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.id]: e.target.checked,
    });
  };

  const allChecked = () => {
    if (allCheckedFlg) {
      // 全チェック -> 全チェック外し
      Object.keys(checkedItems).forEach(function (key) {
        checkedItems[key] = false;
      });
      setCheckedItems(checkedItems);
      if (onChange) {
        onChange([]);
      }
    } else {
      checkableEmissions.forEach(function (emission) {
        checkedItems[emission.id] = true;
      });
      // 全チェック
      setCheckedItems(checkedItems);
      if (onChange) {
        onChange(checkableEmissions.map((v) => v.id));
      }
    }
    setAllCheckedFlg(!allCheckedFlg);
  };

  const onChangeFilter = (key: string, value: string[]) => {
    setTmpFilter({ ...tmpFilter, [key]: value });
  };

  const handleSubmitFilter = () => {
    callApi({ page: DEFAULT_PAGE, filterValue: tmpFilter });
  };

  const tenantCustomOptions = useMemo(() => {
    const unidentifyTenant: OptionItem = {
      id: 0,
      name: t('un_collect.unidentify_tenant'),
    };

    return tenantOptions.concat(unidentifyTenant);
  }, [tenantOptions, t]);

  const chainStatusOptions = useMemo((): OptionItem[] => {
    return [
      {
        id: EmissionStatus.UnRequest,
        name: t('un_collect.chain_status_options.with_chain'),
      },
      {
        id: EmissionStatus.Close,
        name: t('un_collect.chain_status_options.no_chain'),
      },
    ];
  }, [t]);

  return (
    <div className={styles.unCollectList}>
      <div className={styles.unCollectFilter}>
        <div className={styles.filterWrapper}>
          <div className={styles.unCollectFilterBox}>
            <SelectFieldMultipleChoise
              options={itemOptions}
              name="Items"
              searchable={true}
              placeholder={t('common.item') ?? ''}
              onChange={(items) =>
                onChangeFilter(
                  'wasteUnitNames',
                  items.map((item) => String(item.name))
                )
              }
              selectedOptions={
                itemOptions.filter((obj) =>
                  tmpFilter?.wasteUnitNames?.includes(String(obj.name))
                ) || []
              }
              darkMode
              allowClear
            />
          </div>

          <div className={styles.unCollectFilterBox}>
            <SelectFieldMultipleChoise
              options={tenantCustomOptions}
              name="Tenants"
              searchable={true}
              placeholder={t('tenant.thead_tenant') ?? ''}
              onChange={(tenants) =>
                onChangeFilter(
                  'tenantIds',
                  tenants.map((tenant) => String(tenant.id))
                )
              }
              selectedOptions={
                tenantCustomOptions.filter((obj) =>
                  tmpFilter?.tenantIds?.includes(String(obj.id))
                ) || []
              }
              darkMode
              allowClear
            />
          </div>

          <div className={styles.unCollectFilterBox}>
            <SelectFieldMultipleChoise
              options={chainStatusOptions}
              name="chainStatus"
              placeholder={t('un_collect.chain_status') ?? ''}
              onChange={(chain_statuses) =>
                onChangeFilter(
                  'status',
                  chain_statuses.map((status) => String(status.id))
                )
              }
              selectedOptions={
                chainStatusOptions.filter((obj) =>
                  tmpFilter?.status?.includes(String(obj.id))
                ) || []
              }
              darkMode
              allowClear
            />
          </div>

          <div className={styles.unCollectFilterBox}>
            <TableFilterCalendar
              containerClassName={styles.DateRangePicker}
              expanded={{
                placeholder: t('common.placeholder.select_date_range'),
                icon: <IconCalendarTablet />,
              }}
              fullWidth
              allowClear={true}
              onChange={(values) => {
                setTmpFilter((prev) => ({
                  ...prev,
                  dateFrom: values?.[0]
                    ? getSlaFormatYearMonthDate(values[0])
                    : '',
                  dateTo: values?.[1]
                    ? getSlaFormatYearMonthDate(values[1])
                    : '',
                }));
              }}
              value={[tmpFilter?.dateFrom || '', tmpFilter?.dateTo || '']}
              endDateRequired={true}
            />
          </div>
        </div>

        <button
          className={styles.buttonApply}
          onClick={() => handleSubmitFilter()}
        >
          {t('dashboard.button_apply')}
        </button>
      </div>

      <table className={`${styles.tableUnCollectList} ${'primary-table'}`}>
        <thead>
          <tr>
            <th onClick={allChecked} className={styles.selection}>
              <div className={styles.checkAllContainer}>
                <span className={styles.checkAll}>
                  {t('common.collective_selection')}
                </span>
              </div>
            </th>
            <th className={styles.description}>{t('common.item')}</th>
            <th className={styles.weight}>{t('common.weight')}</th>
            <th className={styles.tenant}>{t('tenant.thead_tenant')}</th>
            <th className={styles.datetime}>
              {t('history_emission_card.card_discharge_date_time')}
            </th>
          </tr>
        </thead>
        <tbody>
          {emissions && emissions.length > 0 ? (
            emissions.map((emission, i) => {
              return (
                <tr key={i}>
                  <td className={styles.selection}>
                    {emission.nextStage && (
                      <CustomCheckBox
                        id={emission.id}
                        checked={checkedItems[emission.id] ?? false}
                        onChange={handleChange}
                        darkMode
                      />
                    )}
                  </td>
                  <td className={styles.description}>
                    <div className={styles.unCollectContent}>
                      <p>{emission.description}</p>
                      <UnCollectChainTag
                        collectionOperatorName={emission.collectionOperatorName}
                        emissionStatus={emission.emissionStatus}
                      />
                    </div>
                  </td>
                  <td className={styles.weight}>
                    {normalizeNumber({ value: emission.weight })}&nbsp;kg
                  </td>
                  <td className={styles.tenant}>{emission.tenant?.name}</td>
                  <td className={styles.datetime}>
                    {/* BE return time with JP timezone but has Z in the end of string -> must show UTC to keep data correct */}
                    {getUtcFormatDateTime(
                      emission.datetime,
                      DATE_FORMAT.slaYMDHm
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5} className={styles.msgError}>
                {t('messages.M_008')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
