// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  TenantIndustry,
  createTenant,
  fetchBrands,
  fetchCommonTenantIndustries,
  fetchFloors,
  fetchSections,
  updateTenant,
} from 'apis/tenants';
import TableLoading from 'components/atoms/TableLoading';
import { FileImportIcon } from 'components/atoms/icons/FileImportIcon';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { IconBarcode } from 'components/atoms/icons/IconBarcode';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconEdit } from 'components/atoms/icons/IconEdit';
import { IconMap } from 'components/atoms/icons/IconMap';
import { IconNoBarcode } from 'components/atoms/icons/IconNoBarcode';
import { IconOutlineCloudUpload } from 'components/atoms/icons/IconOutlineCloudUpload';
import { IconSearch } from 'components/atoms/icons/IconSearch';
import AddICCardModal from 'components/molecules/AddICCardModal';
import AdminUploadFileCSVModal from 'components/molecules/AdminUploadFileCSVModal';
import BarcodeDetailModal from 'components/molecules/BarcodeDetailModal';
import DeleteItemModal from 'components/molecules/DeleteItemForm/DeleteItemModal';
import _debounce from 'lodash/debounce';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { ModalType } from 'util/Enums';
import {
  BarcodeICCardInput,
  BarcodeInput,
  BrandItem,
  FloorItem,
  SectionItem,
  TenantForm,
} from 'util/Types';
import { handleError } from 'util/errorHandler';
import AddBarcodeICCardModal from '../AddBarcodeICCardModal';
import BarcodeICCardDetailModal from '../BarcodeICCardDetailModal';
import CreateUpdateTenantModal from '../CreateUpdateTenantModal/CreateUpdateTenantModal';
import styles from './index.module.scss';
import Toggle from 'components/atoms/Toggle';

export interface TenantCreateEditModalType {
  isOpen: boolean;
  type: ModalType;
  tenantEditing?: TenantItem;
}

export type ICCardItem = {
  id: number;
  card: string;
};

export type TenantItem = {
  id: number;
  name: string;
  brand: { id: number; name: string } | null;
  floor: { id: number; name: string } | null;
  section: { id: number; name: string } | null;
  barcode: string;
  industry: string;
  icCards: ICCardItem[];
  tenantNumber: string;
  contactName: string;
  contactEmail: string;
  isDashboardEnabled: boolean;
};

export type FetchTenantData = {
  page: number;
  total: number;
  tenants: TenantItem[];
  siteName: string;
  operatorName: string;
};
type Props = {
  onSetBreadcrumb: (operatorName: string, siteName: string) => void;
};
const AdminTenantManagement: React.FC<Props> = ({ onSetBreadcrumb }) => {
  const { t } = useTranslation();
  const api = createAxios();
  const history = useHistory();
  const { operatorId, siteId } = useParams<{
    operatorId: string;
    siteId: string;
  }>();
  const [tenants, setTenants] = useState<TenantItem[]>([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState(0);
  const [deleteItem, setDeleteItem] = useState<TenantItem>();
  const [search, setSearch] = useState('');
  const [createUpdateModal, setCreateUpdateModal] =
    useState<TenantCreateEditModalType>({
      isOpen: false,
      type: ModalType.add,
    });
  const [sectionList, setSectionList] = useState<SectionItem[]>([]);
  const [floorList, setFloorList] = useState<FloorItem[]>([]);
  const [brandList, setBrandList] = useState<BrandItem[]>([]);
  const [tenantItem, setTenantItem] = useState<TenantItem>();
  const [isBarcodeICCardDetail, setIsBarcodeICCardDetail] = useState(false);
  const [isBarcodeDetail, setIsBarcodeDetail] = useState(false);
  const [isAddBarcodeICCard, setIsAddBarcodeICCard] = useState(false);
  const [tenantIndustries, setTenantIndustries] = useState<TenantIndustry[]>(
    []
  );
  const [isOpenImportCSV, setOpenImportCSV] = useState(false);
  const [isAddICCard, setIsAddICCard] = useState(false);
  const [isShowBarcodeNew, setIsShowBarcodeNew] = useState(false);
  const [genBarcode, setGenBarcode] = useState<BarcodeInput>();
  const [isImportTenantCSV, setIsImportTenantCSV] = useState<boolean>(false);

  const fetchTenants = async (
    keyword?: string,
    nextPage?: number,
    forceRefresh?: boolean
  ) => {
    const params = new URLSearchParams([['siteId', siteId]]);
    if (keyword) {
      params.append('keyword', keyword);
    }

    if (nextPage || !keyword) {
      params.append('page', String(nextPage || DEFAULT_PAGE));
      params.append('take', String(PAGE_SIZE));
    }

    const { data } = await api.get<FetchTenantData>(`/tenants`, { params });
    const { siteName, operatorName } = data;

    if (keyword) {
      setTenants(data.tenants);
    } else {
      setTenants(forceRefresh ? data.tenants : [...tenants, ...data.tenants]);
    }

    onSetBreadcrumb(operatorName, siteName);
    setTotal(data.total);
    setCurrentPage(data.page);
  };

  const fetchListTenantIndustries = () => {
    fetchCommonTenantIndustries((data) => {
      setTenantIndustries(data);
    });
  };

  useEffect(() => {
    fetchSections({ siteId }, (data) => {
      setSectionList(data.sections || []);
    });
    fetchFloors({ siteId }, (data) => {
      setFloorList(data.floors || []);
    });
    fetchBrands({
      callback: (data) => {
        setBrandList(data.brands || []);
      },
    });
    fetchTenants();
    fetchListTenantIndustries();
  }, []);

  // search //
  const debounceFn = useCallback(_debounce(fetchTenants, 1000), []);

  const handleChange = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value.trim());
  };
  const handleSearch = useCallback(() => {
    search && fetchTenants(search);
  }, [search]);
  // end search //

  // handle click
  const handleClickAdd = () => {
    setCreateUpdateModal({
      isOpen: true,
      type: ModalType.add,
    });
  };

  const handleClickEdit = (e: ChangeEvent, item: TenantItem) => {
    e.stopPropagation();
    setCreateUpdateModal({
      isOpen: true,
      type: ModalType.update,
      tenantEditing: item,
    });
  };

  const handleClickDelete = (e: ChangeEvent, item: TenantItem) => {
    e.stopPropagation();
    setDeleteItem(item);
  };

  const handleCreateUpdateTenant = (form: TenantForm, tenantId?: number) => {
    tenantId
      ? updateTenant(form, tenantId, (response) => {
          toast.success(response.data);
          fetchTenants(search, currentPage, true);
          fetchListTenantIndustries();
        })
      : createTenant({ ...form, siteId }, (response) => {
          toast.success(response.data);
          fetchTenants(search, currentPage, true);
          fetchListTenantIndustries();
        });
  };
  const handleTenantDelete = async (id?: number | string) => {
    if (!id) return;

    api
      .delete('/tenants/' + id)
      .then(function () {
        fetchTenants(search, currentPage, true);
        toast.success(t('messages.M_012'));
      })
      .catch(function (e) {
        handleError(e);
      });
    setDeleteItem(undefined);
  };

  // Barcode/ IC card
  const handleBarcodeICCardDetail = (tenantItemData: TenantItem) => {
    setIsBarcodeICCardDetail(true);
    setTenantItem(tenantItemData);
  };

  const handleBarcodeDetail = () => {
    setIsBarcodeICCardDetail(false);
    setIsBarcodeDetail(true);
  };

  const handleAddICCard = () => {
    setIsBarcodeICCardDetail(false);
    setIsAddICCard(true);
  };

  const handleAddBarcodeICCard = (tenantItemData: TenantItem) => {
    setGenBarcode({ barcode: '' });
    setIsAddBarcodeICCard(true);
    setTenantItem(tenantItemData);
  };

  const handleGenBarcodeNew = (tenantItemData: TenantItem | undefined) => {
    setIsAddBarcodeICCard(false);
    setIsShowBarcodeNew(true);

    const tenantId = tenantItemData?.id.toString().padStart(6, '0');
    const barcodeId = '222222' + tenantId;
    setGenBarcode({ barcode: barcodeId });
    toast.success('バーコードが生成されました。');
  };

  const handleSubmitAddICCard = async (
    e: BarcodeICCardInput,
    tenantId?: number
  ) => {
    try {
      await api.post('/tenants/ic-cards', {
        tenantId: tenantId,
        card: e.icCard.trim(),
      });
      fetchTenants(search, currentPage, true);
      toast.success(t('messages.M_010'));
      setIsAddBarcodeICCard(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteICCard = async (icCardId: number) => {
    if (!icCardId) return;

    api
      .delete('/tenants/ic-cards/' + icCardId)
      .then(function () {
        fetchTenants(search, currentPage, true);
        toast.success(t('messages.M_012'));
      })
      .catch(function (e) {
        handleError(e);
      });
  };

  const handleSubmitCreateBarcode = async (
    e: BarcodeICCardInput,
    tenantId: number
  ) => {
    try {
      await api.post('/tenants/barcode', {
        tenantId: tenantId,
        barcode: e.barcode.trim(),
      });
      fetchTenants(search, currentPage, true);
      toast.success(t('messages.M_010'));
      setIsAddBarcodeICCard(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteBarcode = async (barcode: string) => {
    if (!barcode) return;

    api
      .delete('/tenants/barcode/' + barcode)
      .then(function () {
        fetchTenants(search, currentPage, true);
        toast.success(t('messages.M_012'));
        setIsBarcodeICCardDetail(false);
      })
      .catch(function (e) {
        handleError(e);
      });
  };

  const handleClickImportFile = () => {
    setIsImportTenantCSV(true);
    setOpenImportCSV(true);
  };

  const handleImportBarcodeICCard = () => {
    setIsImportTenantCSV(false);
    setOpenImportCSV(true);
  };

  useEffect(() => {
    tenantItem &&
      setTenantItem((tenantItem) =>
        tenants.find((tenant) => tenant.id === tenantItem?.id)
      );
  }, [tenants]);

  // end handle click
  return (
    <div className={styles.adminTenantManagement}>
      <div
        className="count-list"
        dangerouslySetInnerHTML={{
          __html:
            t('common.page', {
              page: total,
            }) ?? '',
        }}
      />

      <div className={`search-area ${styles.searchArea}`}>
        <div className="search-input">
          <input
            placeholder={t('common.placeholder.search') || '検索'}
            value={search}
            onChange={handleChange}
          />
          <div className="icon" onClick={handleSearch}>
            <IconSearch />
          </div>
        </div>
        <div className={styles.buttonOption}>
          <div
            className={`button ${styles.buttonImportTenant}`}
            onClick={handleClickImportFile}
          >
            <FileImportIcon />
            <span>{t('tenant.import_data')}</span>
          </div>
          <div
            className={`button ${styles.buttonImportBarcodeICCard}`}
            onClick={handleImportBarcodeICCard}
          >
            <IconOutlineCloudUpload />
            <span>バーコード・ICカードのインポート</span>
          </div>
          <div
            className={`button ${styles.buttonSection}`}
            onClick={() =>
              history.push(
                `${Path.adminSiteManagement}/${operatorId}/${siteId}/section-floor/management${window.location.search}`
              )
            }
          >
            <IconMap />
            <span>{t('section_floor_management.heading')}</span>
          </div>
          <div className="button" onClick={handleClickAdd}>
            <IconAddCircleOutline />
            <span>{t('common.button.add_tenant')}</span>
          </div>
        </div>
      </div>
      <div className={`scroll-wrapper ${styles.scrollWrapper}`}>
        <table id={styles.customizeItemTable}>
          <thead>
            <tr>
              <th className={styles.name}>{t('tenant.thead_tenant')}</th>
              <th className={styles.section}>{t('tenant.thead_section')}</th>
              <th className={styles.floor}>{t('tenant.thead_floor')}</th>
              <th className={styles.brandName}>{t('tenant.thead_brand')}</th>
              <th className={styles.viewReport}>
                {t('tenant.thead_view_report')}
              </th>
              <th className={styles.action}>{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
            {tenants && tenants.length ? (
              tenants.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className={styles.name}>
                      <div className={styles.contentName} title={item.name}>
                        <p>{item.name || '-'}</p>
                      </div>
                    </td>
                    <td className={styles.section}>
                      <div
                        className={styles.contentSection}
                        title={item.section?.name}
                      >
                        <p>{item.section?.name || '-'}</p>
                      </div>
                    </td>
                    <td className={styles.floor}>
                      <div
                        className={styles.contentFloor}
                        title={item.floor?.name}
                      >
                        <p>{item.floor?.name || '-'}</p>
                      </div>
                    </td>
                    <td className={styles.brandName}>
                      <div
                        className={styles.contentBrandName}
                        title={item.brand?.name}
                      >
                        <p>{item.brand?.name || '-'}</p>
                      </div>
                    </td>
                    <td className={styles.viewReport}>
                      <Toggle value={item.isDashboardEnabled} disabled />
                    </td>
                    <td className={styles.action}>
                      <div className={styles.contentAction}>
                        <div className={`${styles.actionEdit}`}>
                          <IconEdit
                            onClick={(e) => {
                              handleClickEdit(e, item);
                            }}
                          />
                        </div>
                        <div className={`${styles.actionDelete}`}>
                          <IconDelete
                            onClick={(e) => {
                              handleClickDelete(e, item);
                            }}
                          />
                        </div>
                        <div className={`${styles.actionBarcode}`}>
                          {(item.barcode && item.barcode !== null) ||
                          (item.icCards && item.icCards.length > 0) ? (
                            <IconBarcode
                              onClick={() => handleBarcodeICCardDetail(item)}
                            />
                          ) : item.barcode === null ||
                            (item.icCards && !item.icCards.length) ? (
                            <IconNoBarcode
                              onClick={() => handleAddBarcodeICCard(item)}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}
            {tenants && tenants.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (tenants.length >= PAGE_SIZE && tenants.length < total) {
                    setCurrentPage(currentPage + 1);
                    fetchTenants(search, currentPage + 1);
                  }
                }}
              />
            )}
          </tbody>
        </table>

        {deleteItem && (
          <DeleteItemModal
            item={deleteItem}
            onClose={() => setDeleteItem(undefined)}
            onHandleDelete={handleTenantDelete}
          />
        )}

        {createUpdateModal.isOpen && (
          <CreateUpdateTenantModal
            onClose={() => {
              setCreateUpdateModal({
                isOpen: false,
                type: ModalType.add,
              });
            }}
            brandList={brandList}
            floorList={floorList}
            sectionList={sectionList}
            createUpdateModal={createUpdateModal}
            onSubmit={handleCreateUpdateTenant}
            tenantIndustries={tenantIndustries}
          />
        )}

        {isBarcodeICCardDetail && tenantItem && (
          <BarcodeICCardDetailModal
            authenticationItem={{ ...tenantItem, type: 'tenant' }}
            onClose={() => setIsBarcodeICCardDetail(false)}
            onClickShowBarcode={handleBarcodeDetail}
            onClickShowAddICCard={handleAddICCard}
            onClickDeleteBarcode={handleDeleteBarcode}
            onClickDeleteICCard={handleDeleteICCard}
          />
        )}

        {isBarcodeDetail && (
          <BarcodeDetailModal
            barcode={tenantItem}
            onClose={() => {
              setIsBarcodeDetail(false);
              setIsBarcodeICCardDetail(true);
            }}
          />
        )}

        {isAddICCard && tenantItem && (
          <AddICCardModal
            authenticationItem={{ ...tenantItem, type: 'tenant' }}
            onClose={() => {
              setIsAddICCard(false);
              setIsBarcodeICCardDetail(true);
            }}
            onSubmitAddICCard={handleSubmitAddICCard}
          />
        )}

        {isAddBarcodeICCard && (
          <AddBarcodeICCardModal
            genBarcode={genBarcode}
            tenantItemData={tenantItem}
            onClose={() => setIsAddBarcodeICCard(false)}
            onGenBarcodeNew={handleGenBarcodeNew}
            onSubmitAddICCard={handleSubmitAddICCard}
            onSubmitCreateBarcode={handleSubmitCreateBarcode}
          />
        )}

        {isShowBarcodeNew && (
          <BarcodeDetailModal
            barcode={genBarcode}
            itemName={tenantItem?.name}
            onClose={() => {
              setIsShowBarcodeNew(false);
              setIsAddBarcodeICCard(true);
            }}
          />
        )}
        {isOpenImportCSV && (
          <AdminUploadFileCSVModal
            onClose={() => {
              setOpenImportCSV(false);
            }}
            onSubmitImportFile={() => {
              setOpenImportCSV(false);
              fetchTenants(search, currentPage, true);
              fetchSections({ siteId }, (data) => {
                setSectionList(data.sections || []);
              });
              fetchFloors({ siteId }, (data) => {
                setFloorList(data.floors || []);
              });
              fetchBrands({
                callback: (data) => {
                  setBrandList(data.brands || []);
                },
              });
              fetchListTenantIndustries();
            }}
            endPoint={
              isImportTenantCSV
                ? `/tenants/import?siteId=${siteId}`
                : `/tenants/import/authen?siteId=${siteId}`
            }
          />
        )}
      </div>
    </div>
  );
};

export default AdminTenantManagement;
